/**
 * @title App
 * @description Application entry point
  */


// Jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;
// var Modernizr = require("browsernizr");

// Barba - pages transition
import barba from "@barba/core";
import barbaCss from "@barba/css";

import Flickity from "flickity";
import "flickity/css/flickity.css";
require("flickity-fade");
require("flickity-bg-lazyload");
require("flickity-as-nav-for");

// Gallery
import lightGallery from "lightgallery";
import "lg-video";

// Modal
import modal from "jquery-modal";

// Lazyload image
import LazyLoad from "vanilla-lazyload";

// Infinite scroll
var InfiniteScroll = require("infinite-scroll");

// Google maps api
import { Loader } from "@googlemaps/js-api-loader";

// Components
import spinner from "./components/spinner";
// import "./components/thom_dealers";

// Cookie
import {thomCookieSet, thomCookieGet, thomCookieDel} from "./components/thom_cookie";

require("./components/thom_advanced_search");

var jQBridget = require("jquery-bridget");
var Packery = require("packery");
$.bridget("packery", Packery, $);

var imagesLoaded = require("imagesloaded");

require("jquery.resizeend");

// Thom Form
require("./components/thom_form");

// Thom Map
require("./components/thom_map");

// Modules
import navbar from "../modules/navbar/navbar";
import searchOverlay from "../modules/navbar/search-overlay";
require("../modules/dropdown/dropdown");
import thomDropdown from "../modules/dropdown/dropdown";
import {toa, toaInit, toaUpdate, toaDisable, toaParallaxMove} from "../modules/thomObserveAnimation/toa";

// paginationom "twbs-pagination"
// require("twbs-pagination");

// import "@chenfengyuan/datepicker/dist/datepicker.min.js";
// import "@chenfengyuan/datepicker/i18n/datepicker.it-IT";
// import "@chenfengyuan/datepicker/i18n/datepicker.de-DE";
// import "@chenfengyuan/datepicker/i18n/datepicker.fr-FR";
// import "@chenfengyuan/datepicker/i18n/datepicker.pl-PL";
// import "@chenfengyuan/datepicker/i18n/datepicker.es-ES";
// import "@chenfengyuan/datepicker/i18n/datepicker.pt-BR";
// import "@chenfengyuan/datepicker/i18n/datepicker.ru-RU";
// import "@chenfengyuan/datepicker/i18n/datepicker.zh-CN";
// import "@chenfengyuan/datepicker/i18n/datepicker.ar-IQ";
// import "@chenfengyuan/datepicker/dist/datepicker.min.css";

import Rellax from "rellax";

import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

// exist element
$.fn.exists = function () {
  return this.length !== 0;
};

// Modal default options
$.modal.defaults = {
  clickClose: true,
  escapeClose: true,
  fadeDuration: 250,
  fadeDelay: 1.1,
  showClose: false,
  spinnerHtml: '<div id="spinner" class="default"><div class="sk-chase"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div></div>',
  blockerClass: "jquery-modal"
}

var myLazyLoad;

ScrollTrigger.config({ limitCallbacks: true });

var isMobile = false;
 //initiate as false
// device detection
function isMobileDevice(){
  if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
      isMobile = true;
  } else {
    isMobile = false;
  }

  return isMobile;
}

function parallaxAnimation(){

  var hasParallax = false;
  var isMobileParallax = false;
  let tl;

  // Init
  function parallaxAnimationInit(){

    tl = gsap.timeline();
    let scrollargs = {
      trigger: ".parallax-scroller",
      start: "top 100%",
      // markers: true,
      animation: tl,
      scrub: true
    }

    ScrollTrigger.create(scrollargs);
    
    hasParallax = true;

    if (isMobileDevice()) {
      isMobileParallax = true;
    } else {
      isMobileParallax = false;
    }

    const h = window.innerHeight;

    gsap.utils.toArray(".parallax-item").forEach((layer, i) => {
      
      let layerH = layer.offsetHeight;
      let speed = layer.dataset.speed;

      // console.log(layerH)
      if (isMobileDevice()) {
        speed = speed / 10;
      }

      let movement = layerH * speed;
      
      
      // console.log(speed)
      
      // speed = (h * speed) / layerH
      
      /* if (isMobileDevice()) {
        speed = speed / 6;
      } */

      // let movement = speed * 10;

      // console.log(speed)

      /* if(layer.dataset.start){
        var startA = layer.dataset.start;
        if (isMobileDevice()) {
          startA = startA / 6;
        }
        gsap.set(layer, 
          {
            yPercent: startA * 10,
            ease: "none"
          });
      } else {
        gsap.set(layer, 
          {
            yPercent: -1 * movement,
            ease: "none"
          });
      } */
      
      tl.to(layer, 
        {
          y: movement,
          ease: "none"
        }, 0);  
    });
  }

  // Reset
  function parallaxAnimationReset () {
    var st = ScrollTrigger.getAll();
    st.forEach((stE, index, array) => {
      stE.kill(true);
    })
    tl.kill(true);  
    $(".parallax-item").removeAttr('style');
    ScrollTrigger.refresh();
    hasParallax = false;
    parallaxAnimationInit();
  }

  $(window).on("resizeend", 250, function () {
    if (isMobileDevice()) {
      if(!isMobileParallax && hasParallax){
        parallaxAnimationReset();
      }
    } else {
      if(isMobileParallax && hasParallax){
        parallaxAnimationReset();
      }
    }
  });
  
  if(!hasParallax){
      parallaxAnimationInit();
  }
}

// Form Response Action
function MyThomFormResponseAction(title, text) {
	var mytitle;
	if (title !== undefined && title !== null && title !== "") {
	mytitle = "<h2 class=''>" + title + "</h2>";
	} else {
	mytitle = "";
	}
  
	var myHtml =
	'<div class="modal modal-callback">' +
		'<a href="#close" rel="modal:close" class="close"><img src="/img/ico-close.svg"></a>' +
		mytitle +
		"<div class='paragraph'>" + text + "</div>"; +
	'</div>';
  
	$(myHtml).appendTo("body").modal();
}

// Start APP
function myapp() {
  // reset navigation set
  $("body")
    .removeClass("navbar--search-opened")
    .removeClass("navbar--opened");

  $(".wrapper").removeAttr("style");
  $(".main").removeAttr("style");
  window.scrollBy(0, 0);
  let packeryInstance = null;
  myLazyLoad = null;   


  imagesLoaded( document.querySelector('.parallax-scroller'), function( instance ) {
    // console.log(instance)
    myLazyLoad = new LazyLoad({
      unobserve_entered: true,
      elements_selector: ".lazy",
      callback_loaded: function(el){
        ScrollTrigger.update();
      }
    });

    // var rellax = new Rellax('.parallax-item');
    parallaxAnimation();
  });

  // Carousel
  if ($(".js-carousel").exists()) {
    var elem = $(".js-carousel");
    $(".js-carousel").each(function(){
      var $this = this;
      new Flickity($this, {
        autoPlay: 0,
        cellSelector: ".carousel-cell",
        pauseAutoPlayOnHover: true,
        prevNextButtons: false,
        pageDots: true,
        bgLazyLoad: true,
        fade: true,
        lazyLoad: 2,
        draggable: true,
        on: {
          ready: function () {
            $($this).removeClass('is-hidden');
            ScrollTrigger.update();
          },
        },
      });
    })
  }

  // Ajax modal
  $('a[rel="ajax:modal"]').unbind('click');
  $('a[rel="ajax:modal"]').click(function (event) {
    event.preventDefault();
    this.blur();
    $.ajax({
      url: $(this).attr("href"),
      success: function (newHTML, textStatus, jqXHR) {
        var myHtml = $(newHTML).find(".ajax-load");
        $(myHtml).appendTo("body").modal();
      },
      error: function (jqXHR, textStatus, errorThrown) {
        // Handle AJAX errors
      },
      complete: function () {},
      // More AJAX customization goes here.
    });

    return false;
  });

  $('.scrollto').click(function(){
    var link = $(this).data('link');
    
    $("html,body").animate(
      {
        scrollTop: $('#' + link).offset().top - $('.navbar').height(),
      },
      600
    );
  })

  // open video popup
  $(".btn-video").lightGallery({
    selector: "this",
    download: false,
    counter: false,
    videoMaxWidth: "80%",
    getCaptionFromTitleOrAlt: false,
    youtubePlayerParams: {
      modestbranding: 1,
      showinfo: 0,
      rel: 0,
      controls: 0,
    },
    vimeoPlayerParams: {
      byline: 0,
      portrait: 0,
    },
    afterOpen: function(){
      console.log('cazzzz')
    }
  });

  // ContactMap();
	if ($("#contactForm").exists()) {
    var contactForm = $("#contactForm");
    contactForm.thomForm({
      errorMsg: contactForm.find(".thom-form__error"),
      errorCallback: function (myTitle, myMessage) {
        if (myTitle) {
          myTitle =
            "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
        }
        if (myMessage) {
          myMessage = "<div class='paragraph'>" + myMessage + "</div>";
        }

        if (myTitle || myMessage) {
          contactForm.find(".thom-form__error").html(myTitle + myMessage);
        }

        contactForm.find(".thom-form__error").fadeIn(300, function () {
          $("html,body").animate(
            {
              scrollTop: contactForm.offset().top - $("header").height() - 20,
            },
            300
          );
        });
      },
      myCallback: function (myTitle, myMessage) {
        MyThomFormResponseAction(myTitle, myMessage);
      },
    });
  }


  if (typeof analyticsUpdate === "function") {
    analyticsUpdate();
  }
}



// start script
barba.use(barbaCss);

// define a global hook
barba.hooks.once((data) => {
  // this hook will be called for each transitions
  // console.log('APPEAR - global hook')
  
  navbar();
  myapp();

  // Remove appended div to modal after close
  $(document).on($.modal.AFTER_CLOSE, function (event, modal) {
    $(".ajax-load.modal").remove();
  });

  // Analytics update
  $(document).on($.modal.OPEN, function (event, modal) {
    if (typeof analyticsUpdate === "function") {
      analyticsUpdate();
    }
    $(modal.$anchor).find(".close-modal").attr("data-barba-prevent", "self");
  });
  $(document).on($.modal.CLOSE, function (event, modal) {
    if (typeof analyticsUpdate === "function") {
      analyticsUpdate();
    }
  });

  $("#spinner").removeClass("default");
  console.log('once completed')
  // setTimeout(function() {
  //   $("#spinner");.remove()
  // }, 800);
});

barba.hooks.enter((data) => {
  console.log('barba enter')
  $.ajax({
    url: data.next.url.href,
    success: function (newHTML, textStatus, jqXHR) {
      // Meta update
      var headTags = [
        "meta[name='keywords']",
        "meta[name='description']",
        "meta[property^='og']",
        "meta[name^='twitter']",
        "meta[itemprop]",
        "link[itemprop]",
        "link[rel='prev']",
        "link[rel='next']",
        "link[rel='canonical']",
      ].join(",");
      $("head").find(headTags).remove();
      $(newHTML).find(headTags).appendTo("head");

      // Section placeholder Change
      var newplaceholdersection = $(newHTML)
        .find(".placeholder-section")
        .html();
      $(".navbar .placeholder-section").html(newplaceholdersection);

      // Menu Change
      var newmenu = $(newHTML).find(".navbar__collapse").html();
      $(".navbar .navbar__collapse").html(newmenu);

      // Footer
      // var newfooter = $(newHTML).find('.footer-path').html();
      // $(".footer .footer-path").html(newfooter);

      // script variables
      var js = $(newHTML).find("script");
      if (js != null) {
        $.globalEval(js.html());
      }


      if($(newHTML).find(".js-toa").length > 0){
        toaDisable();
      }

      myapp();
    },
    error: function (xhr, ajaxOptions, thrownError) {
      // alert(xhr.status)
      // alert(xhr.statusText)
      // alert(thrownError)
    },
    complete: function () {
      spinner().hide();
    },
  });
});
barba.hooks.leave((data) => {
  // this hook will be called for each transitions
  // console.log("LEAVE - global hook");
  spinner().show();

  document.body.classList.remove("navbar--opened");

  // close an open modal
  $.modal.close();

  $("html,body").animate(
    {
      scrollTop: 0,
    },
    600
  );
});

barba.hooks.before((data) => {});

// Basic default transition, with no rules and minimal hooks…
barba.init({
  timeout: 5000,
  cacheIgnore: true,
  prefetchIgnore: true,
  debug: true,
  transitions: [{
    name: 'fade',
    leave() {},
    enter() {}
  }],
  views: [
    {
      namespace: "form",
      afterEnter(data) {
        if ($(".thom-form__recaptcha").length != 0) {
          recaptchaLoad();
        }

        if ($(".mtcaptcha").length != 0) {
          mt_jsloadedcb();
        }
      }
    }
  ]
});

$(function() {});
